.vessel-image {
    width: 100%;
    height:410px;
}

.crop-image{
    display: inline-block;
    width:100%;
    height: 400px;
    overflow: hidden;
}

.country-icon{
    width: auto;
    height: 40px;
    margin-left:15px;
    border-radius: 5px;
}

.vessel-card{
    margin: 15px 30px 0px 30px;
}