.leaflet-container {
  width: 100%;
  height: 70vh;
}

.leaflet-control-velocity {
  background: rgba(255, 255, 255, 0.8);
  padding: 0px 5px 0px 5px;
}

.leaflet-popup-close-button {
  display: none;
}

.leaflet-popup-tip,
.leaflet-popup-content-wrapper {
  background: rgba(255, 255, 255, 0.9);
}

.heatmap-legend {
  background: linear-gradient(to left, rgba(178, 31, 31, 1) 0%, 3.0516432598233223%, rgba(209, 63, 66, 1) 6.103286519646645%, 9.859155304729939%, rgba(169, 85, 77, 1) 13.615024089813232%, 19.718310236930847%, rgba(231, 155, 45, 1) 25.821596384048462%, 27.699530124664307%, rgba(242, 204, 47, 1) 29.57746386528015%, 31.924882531166077%, rgba(190, 245, 45, 1) 34.272301197052%, 35.915493965148926%, rgba(175, 245, 54, 1) 37.55868673324585%, 39.906103909015656%, rgba(145, 243, 88, 1) 42.25352108478546%, 46.24413102865219%, rgba(70, 209, 106, 1) 50.23474097251892%, 53.05164158344269%, rgba(60, 205, 149, 1) 55.868542194366455%, 59.859153628349304%, rgba(60, 230, 200, 1) 63.84976506233215%, 66.431924700737%, rgba(34, 193, 233, 1) 69.01408433914185%, 72.24897742271423%, rgba(0, 130, 210, 1) 75.48387050628662%, 80.83871006965637%, rgba(113, 129, 235, 1) 86.19354963302612%, 93.09677481651306%, rgba(134, 31, 234, 1) 100%);
  position: absolute;
  bottom: 1%;
  right: 1%;
  padding: 0px 5px 0px 5px;
  margin: 0;
  z-index: 1000;
  border-radius: 3%/50%;
  width: 25%;
  color: white;
  font-weight: bold;
}

.toggle-switch {
  position: relative;
  width: auto;
  height: auto;
  display: inline-block;
  text-align: left;
  font-size: 7px;
}

.checkbox {
  display: none;
}

.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  height: 20px;
  width: 50px;
}

.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 20px;
  line-height: 20px;
  padding: 0;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}

.inner:before {
  content: "LIGHT";
  padding-left: 10px;
  background-color: #a0c7ee;
  color: #fff;
}

.inner:after {
  content: "DARK";
  padding-right: 10px;
  background-color: #2e2e2e;
  color: #fff;
  text-align: right;
}

.switch {
  display: block;
  width: 10px;
  height: 10px;
  margin: 5px;
  background: #fff;
  position: absolute;
  right: 30px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.checkbox:checked+.label .inner {
  margin-left: 0;
}

.checkbox:checked+.label .switch {
  right: 0px;
}