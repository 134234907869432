.blue-text {
    color: #374790;
    font-family: Helvetica, sans-serif;
}

.download-button {
    color: white;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    margin: 10px 0px;
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
    border: none;
}

.smoothing-button {
    color: white;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    margin: 10px 0px;
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
    border: none;
}

.summary-cell {
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 500;
    vertical-align: middle;
}

.collapside-cell {
    padding-bottom: 15px;
}

.Collapsible__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
.Collapsible__trigger.is-open svg {
    transform: rotate(180deg);
}