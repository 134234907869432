.button {
    color: #2d4287;
    border-color: #2d4287;
}

.button:hover {
    background-color: #2d4287;
    color: white;
}

.vessel-filter{
    margin-top: 10px;
    margin-left: 10px;
}

.vessel-filter-section{
    padding-top: 3px;
    padding-bottom: 3px;
}

.filter-slider{
    margin-left: 10px;
    width:85% !important;
    margin-bottom: 25px !important;
}

.velocity-slider{
    width:50% !important;
}

.blue-text{
    color: #2D4492;
}

.route-selector{
    margin-top: 10px;
    position: relative;
    z-index: 1000;
}

.map-wrapper{
    margin: 15px 30px 15px 30px;
    height:300px;
    z-index: 100;
    position: relative;
}

.leaflet-container {
    height: 100%;
}

.card {
    border-radius: 30px;
    background: white;
    box-shadow: 15px 15px 30px #bebebe,
                -15px -15px 30px #ffffff;
    border-width: 0px;
    overflow: hidden;
}

.section-card{
    padding: 15px 30px 15px 30px;
    margin: 15px 0px 30px 0px;
    overflow: visible !important;
}

.section-title{
    font-size: 1.5em !important;
    font-weight: 500 !important;
    padding-left: 0px !important;
    margin-bottom:5px !important;
}

.vessel-filter-selector{
    position:relative;
    z-index: 2000;
}

.vessel-selector{
    position:relative;
    z-index: 3000;
}

.map-card{
    box-shadow:none;
}

.run-button {
    align-items: center;
    appearance: none;
    background-color: white;
    border-radius: 4px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
    box-sizing: border-box;
    color: #2D4492;
    cursor: pointer;
    display: inline-flex;
    /*font-family: "JetBrains Mono",monospace; This font is really cool and could be used on the whole web*/
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 18px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.run-button:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.run-button:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
  color:white;
  background-color: #2d4287;
}

.run-button:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}

.run-button-disabled {
  box-shadow: #D6D6E7 0 0 0 1.5px inset;
  color: #D6D6E7;
  cursor: not-allowed;
  pointer-events: none;
}